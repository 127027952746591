import { IUserDataSummary, IUserSettings } from '@models';
import {
  IAccount,
  IAccountLoginBody,
  IAccountRegisterBody,
  ILoginAccount,
} from '@models/account';
import { createAction, props } from '@ngrx/store';

export const SummaryRequest = createAction('USER_SUMMARY_REQUEST');
export const SummarySuccess = createAction(
  'USER_SUMMARY_SUCCESS',
  props<{ summary: IUserDataSummary }>()
);
export const AccountRequest = createAction('USER_ACCOUNT_REQUEST');
export const AccountUpdateRequest = createAction(
  'USER_ACCOUNT_UPDATE_REQUEST',
  props<{ form: Partial<IAccount> }>()
);
export const AccountUpdateError = createAction('USER_ACCOUNT_UPDATE_ERROR');
export const AccountUpdatePicRequest = createAction(
  'USER_ACCOUNT_PIC_UPDATE_REQUEST',
  props<{ image: Blob }>()
);
export const AccountSuccess = createAction(
  'USER_ACCOUNT_SUCCESS',
  props<{ account: IAccount }>()
);
export const AgreeToTermsRequest = createAction('USER_AGREE_TO_TERMS_REQUEST');
export const AgreeToTermsSuccess = createAction('USER_AGREE_TO_TERMS_SUCCESS');

export const SettingsRequest = createAction('USER_SETTINGS_REQUEST');
export const SettingsSuccess = createAction(
  'USER_SETTINGS_SUCCESS',
  props<{ settings: IUserSettings }>()
);
export const SettingsUpdateRequest = createAction(
  'USER_SETTINGS_UPDATE_REQUEST',
  props<{ settings: Partial<IUserSettings> }>()
);
export const SettingsUpdateSuccess = createAction(
  'USER_SETTINGS_UPDATE_SUCCESS',
  props<{ settings: IUserSettings }>()
);
export const UserDeleteRequest = createAction('USER_DELETE_REQUEST');
export const UserDeleteSuccess = createAction('USER_DELETE_SUCCESS');
export const UserError = createAction('USER_ERROR');
export const Unauthorized = createAction('USER_401');
export const LogoutRequest = createAction('USER_LOGOUT_REQUEST');
export const LogoutSuccess = createAction('USER_LOGOUT_SUCCESS');
export const RegisterRequest = createAction(
  'USER_REGISTER_REQUEST',
  props<{ form: IAccountRegisterBody }>()
);
export const LoginRequest = createAction(
  'USER_LOGIN_REQUEST',
  props<{ form: IAccountLoginBody }>()
);
export const LoginProviderRequest = createAction(
  'USER_LOGIN_PROVIDER_REQUEST',
  props<{
    form: {
      provider: string;
      providerId;
      Email: string;
      FirstName: string;
      LastName: string;
      AvatarUrl: string;
    };
  }>()
);
export const LoginSuccess = createAction(
  'USER_LOGIN_SUCCESS',
  props<{ account: ILoginAccount }>()
);

export type ActionsUnion =
  | typeof SummaryRequest
  | typeof SummarySuccess
  | typeof SettingsRequest
  | typeof SettingsSuccess
  | typeof SettingsUpdateRequest
  | typeof SettingsUpdateSuccess
  | typeof UserDeleteRequest
  | typeof UserDeleteSuccess
  | typeof UserError
  | typeof LogoutRequest
  | typeof LogoutSuccess
  | typeof AccountRequest
  | typeof AccountSuccess
  | typeof AgreeToTermsRequest
  | typeof AgreeToTermsSuccess
  | typeof LoginRequest
  | typeof LoginSuccess;
